const Logo = (props) => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="16.5" cy="16.5" r="12.5" fill="#111827" />
    <path
      d="M13.9355 16C13.9355 15.4409 14.1398 14.957 14.5484 14.5484C14.957 14.1398 15.4409 13.9355 16 13.9355C16.5591 13.9355 17.043 14.1398 17.4516 14.5484C17.8602 14.957 18.0645 15.4409 18.0645 16C18.0645 16.5591 17.8602 17.043 17.4516 17.4516C17.043 17.8602 16.5591 18.0645 16 18.0645C15.4409 18.0645 14.957 17.8602 14.5484 17.4516C14.1398 17.043 13.9355 16.5591 13.9355 16ZM4.64516 4.70968C7.78496 1.56988 11.5699 0 16 0C20.4301 0 24.2043 1.55912 27.3226 4.67742C30.4409 7.79571 32 11.5699 32 16C32 20.4301 30.4409 24.2043 27.3226 27.3226C24.2043 30.4409 20.4301 32 16 32C11.5699 32 7.79571 30.4409 4.67742 27.3226C1.55912 24.2043 0 20.4301 0 16C0 11.5699 1.54837 7.80647 4.64516 4.70968ZM24.129 9.54839C24.3011 9.2043 24.3118 8.86022 24.1613 8.51613C24.0108 8.17204 23.7849 7.94624 23.4839 7.83871C23.1828 7.73118 22.8387 7.74193 22.4516 7.87097L13.1613 12.129C12.6882 12.3441 12.3441 12.6882 12.129 13.1613L7.87097 22.4516C7.6129 22.9677 7.69892 23.4409 8.12903 23.871C8.55914 24.3011 9.03226 24.3871 9.54839 24.129L18.8387 19.871C19.3118 19.6559 19.6559 19.3118 19.871 18.8387L24.129 9.54839Z"
      fill="#facc15"
    />
  </svg>
);

export default Logo;
